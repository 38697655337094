<template>
  <div class="checkout">
    <el-container>
      <el-main class="checkout-box">
        <div class="header">
          <div class="header-title">结算中心</div>
          <div class="order-steps">
            <el-steps align-center :active="active">
              <el-step title="提交订单"></el-step>
              <el-step title="支付订单"></el-step>
              <el-step title="支付完成"></el-step>
            </el-steps>
          </div>
        </div>
        <div class="content">
          <div class="order-info">
            <div class="order-info-left">
              <div>订单提交成功，请尽快支付！</div>
              <!-- <div>订单提交成功，请尽快支付！订单编号：O65457329889705984</div> -->
              <div class="count-down-area">
                <span>请您在</span>
                <div class="count-down" style="color: rgb(224, 32, 32)">
                  {{ hr }}小时 {{ min }}分钟 {{ sec }}秒
                </div>
                <span>内完成支付，否则订单会被自动取消</span>
              </div>
              <!---->
            </div>
            <div class="order-info-right">
              <div class="payment-price">
                <span class="lable">应付金额</span
                ><span class="value">{{ totalPrice }}元</span>
              </div>
            </div>
          </div>
          <div class="view-order-btn">
            <span @click="changeOrderShow">{{ orderShowTitle }}</span>
            <el-table
              :data="tableData"
              stripe
              style="width: 100%"
              v-if="orderShow"
            >
              <el-table-column prop="title" label="活动"> </el-table-column>
              <el-table-column prop="offset" label="票种" width="200">
              </el-table-column>
              <el-table-column prop="number" label="数量" width="200">
              </el-table-column>
              <el-table-column prop="price" label="金额" width="200">
              </el-table-column>
            </el-table>
          </div>
          <div class="select-payment-method">
            <div class="content">
              <div class="payment-type">线上支付</div>
              <div class="select-payment">
                <!-- <el-radio v-model="payment" label="1">支付宝</el-radio> -->
                <el-radio v-model="payment" label="1">微信</el-radio>
              </div>
            </div>
          </div>
          <div class="footer-bar">
            <div class="footer-bar-pc">
              <div class="confirm-btn">
                <button type="button" class="el-button el-button--primary">
                  <span @click="payShwDialog">立即支付</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :before-close="handleClose"
      >
        <span>
          <div class="payment-price">
            <span>支付金额：{{ totalPrice }}元</span>
          </div>
          <div class="qr-code">
            <span>请用微信扫一扫付款</span>
            <div class="middle-icon">
              <canvas class="middle-icon" ref="wxPayQRCode"></canvas>
              <img
                src="https://tickets.foodtalks.cn/static/img/wechatpay.2a74c018.png"
              />
            </div>
            <!-- <canvas class="middle-icon" ref="wxPayQRCode"></canvas> -->
          </div>
          <!-- <el-button @click="websiteOrder()">完成支付</el-button> -->

          <div class="pay-warning">
            <div></div>
            <div class="count-down-area">
              <span>请您在</span>
              <div class="count-down">
                <div class="van-count-down" style="color: rgb(224, 32, 32)">
                  {{ hr }}小时 {{ min }}分钟 {{ sec }}秒
                </div>
              </div>
              <span>内完成支付，否则订单会被自动取消</span>
            </div>
          </div>
        </span>
        <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false"
            >确 定</el-button
          >
        </span> -->
      </el-dialog>
    </el-container>
  </div>
</template>

<script>
import { websiteOrder, checkOrder, insertPageOrder } from "@/api/index";
import QRCode from "qrcode";
export default {
  name: "checkout",
  components: {},
  data() {
    return {
      num: 1,
      active: 2,
      payType: 1,
      payment: "1",
      orderShowTitle: "查看订单详情",
      hr: 0,
      min: 0,
      sec: 0,
      orderId: "",
      orderShow: false,
      totalPrice: "",
      imgUrl: "",
      nowtime: "",
      userInfo: "",
      tableData: [
        {
          title: "FBIF2023 论坛票",
          offset: "Carbontech2023 通票",
          number: "1",
          price: "5800元",
        },
      ],
      ticketInfo: [],
      timer: undefined, // 计时器，作用：组件销毁的时候需要把定时器清除
      dialogTitle: "",
      dialogVisible: false,
      exhibitionUUid:"",
    };
  },
  created() {
      this.exhibitionUUid = window.sessionStorage.getItem("ticketUuid")
    this.getChoseTicketInfo();
    this.getTotalPrice(this.num);
    this.getUserInfo();
  },
  mounted() {
    this.futureDay();
  },

  methods: {
    getUserInfo() {
      this.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
      console.log(this.userInfo);
      console.log( Date.now())
    },
    getChoseTicketInfo() {
      this.ticketInfo = JSON.parse(window.sessionStorage.getItem("ticketInfo"));
      console.log(this.ticketInfo);
      this.num = window.sessionStorage.getItem("choseTicketNum");
      this.tableData[0].title = this.ticketInfo.name;
      this.tableData[0].number = this.num;
    },
    getTotalPrice(num) {
            this.totalPriceNoDiscount = this.ticketInfo.price * num
      if (num < 5) {
        this.totalPrice = this.ticketInfo.price * num;
    this.originalPrice_show =false
      } else if (5 <= num < 10) {
        this.totalPrice = this.ticketInfo.price * num * 0.9;
        this.originalPrice = this.ticketInfo.price * num * 0.1;
       this.originalPrice_show =true
        this.discount_zheNow = 9
      } if(this.num >= 10) {
        this.totalPrice = this.ticketInfo.price * num * 0.8;
        this.originalPrice = this.ticketInfo.price * num * 0.2;
     this.originalPrice_show =true
            this.discount_zheNow = 8
      }
      // this.totalPrice = this.ticketInfo.price * this.num;
      this.tableData[0].price = this.totalPrice + "元";
    },
    handleChange(value) {
      console.log(value);
    },

    futureDay() {
      //未来某天的倒计时
      // 1、获取未来的日期
      // 2、获现在的时间
      // 3、未来的日期➖现在的时间
      // 4、得到相减的结果，换算为，天-小时-分钟-秒
      // 5、每隔1秒获取一次，直至相减为0（需自己做限制）
      const end = Date.parse(new Date("2030-01-01"));
      const now = Date.parse(new Date());
      const msec = end - now;
      let hr = parseInt((msec / 1000 / 60 / 60) % 24);
      let min = parseInt((msec / 1000 / 60) % 60);
      let sec = parseInt((msec / 1000) % 60);
      this.hr = hr > 9 ? hr : "0" + hr;
      this.min = min > 9 ? min : "0" + min;
      this.sec = sec > 9 ? sec : "0" + sec;

      setTimeout(() => {
        this.futureDay();
      }, 1000);
    },
    changeOrderShow() {
      this.orderShow = !this.orderShow;
      if (this.orderShow == true) {
        this.orderShowTitle = "收起订单详情";
      } else {
        this.orderShowTitle = "查看订单详情";
      }
    },
    payShwDialog() {
      this.dialogTitle = "微信扫码支付";
      this.dialogVisible = true;
      this.websiteOrder();
    },
    // getWxPay() {
    //   getWxPay(this.totalPrice * 100).then((res) => {
    //     console.log(res);
    //     QRCode.toCanvas(this.$refs.wxPayQRCode, res.data.msg);
    //     this.websiteOrder();
    //   });
    // },
    //线上购票接口生成订单
    websiteOrder() {
      let data = {
        exhibitionUUid: this.exhibitionUUid,
        ticketUUid: this.ticketInfo.uuid,
        name: "黄11（测试姓名）",
        mobile: "1345678909",
        corp: "德泰（测试公司）",
        position: "开发（测试职务）",
        free: this.totalPrice,
      };
      websiteOrder(data).then((res) => {
        console.log(res);
        QRCode.toCanvas(this.$refs.wxPayQRCode, res.data.data.wxUrl);
        this.orderId = res.data.data.orderNo;
        this.checkOrder(this);
      });
    },

    //查询订单状态
    checkOrder(that) {
      console.log(that.orderId);
      that.getNowTime()
    var interval =  window.setInterval(() => {
        setTimeout(function () {
          checkOrder(that.orderId).then((res) => {
            console.log(res);
      
            if (res.data.code == 200) {
              that.creatPageOrder()
             
              that.dialogVisible = false;
              that.$notify({
                title: "购买成功",
                message: "请前往我的订单查看",
                type: "success",
              });
              clearInterval(interval)
            }
          });
        }, 2000);
      }, 6000);
    },
    creatPageOrder() {
      let data = {
        order_no: this.orderId,
        order_phone: this.userInfo.userName,
        order_amount: this.ticketInfo.price * this.num,
        order_actual_pay: this.ticketInfo.price * this.num,
        order_creattime: Date.now(),
        order_name: this.ticketInfo.name,
        ticket_price: this.ticketInfo.price,
        ticket_num:Number(this.num) ,
      };
      insertPageOrder(data).then((res) => {
        console.log(res);
      });
    },
    getNowTime() {
      let dt = new Date();
      var y = dt.getFullYear();
      var mt = (dt.getMonth() + 1).toString().padStart(2, "0");
      var day = dt.getDate().toString().padStart(2, "0");
      var h = dt.getHours().toString().padStart(2, "0");
      var m = dt.getMinutes().toString().padStart(2, "0");
      this.nowtime = y + "-" + mt + "-" + day + " " + h + ":" + m;
      console.log("此刻时间", this.nowtime);
    },

    handleClose(done) {
      done();
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
   width: 60%;
  .el-dialog__header {
    background-color: #eef0f1;
    border: 1px solid #dcdfe6;
    .el-dialog__title {
      font-weight: 700;
      font-size: 15px;
    }
  }
  .el-dialog__body {
    padding-bottom: 40px;
    text-align: center;
    padding-top: 40px;
    .payment-price {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 15px;
      span {
        color: #003e89;
      }
    }
    .qr-code {
      margin: 0 auto;
      border: 1px solid #dcdfe6;
      // width: 380px;
      .middle-icon {
        width: 308px !important;
        height: 308px !important;
        margin: 0 auto;
        background: no-repeat top;
        background-size: contain;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        img {
          position: absolute;
          width: 64px;
        }
      }
      span {
        display: inline-block;
        margin-top: 12px;
        font-weight: 700;
      }
    }
    .pay-warning {
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: 16px;
      margin-top: 15px;
      .count-down-area {
        margin-top: 8px;
        // display: flex;
        // -webkit-box-align: baseline;
        // -ms-flex-align: baseline;
        // align-items: baseline;
      }
    }
  }
}

.checkout {
  .checkout-box {
    // display: flex;
    max-width: 1200px;
    min-height: 485px;
    margin: 32px auto;
    padding: 24px;
    border: 1px solid #eaeaea;
    background: #fff;
    .header {
      font-size: 18px;
      color: #333;
      width: 100%;
      font-weight: 700;
      margin: 16px 0 34px 0;
      position: relative;
      .order-steps {
        position: absolute;
        width: 360px;
        right: 0;
        top: -10px;
        .el-steps {
          font-weight: 400;
          justify-content: center;
          /deep/.is-finish {
            color: #003e89;
            border-color: #003e89;
            .el-step__icon {
              background: #003e89;
              color: #fff;
            }
          }

          /deep/.el-step__title {
            font-size: 12px;
            line-height: 26px;
            .is-process {
              font-weight: 500;
              color: #303133;
            }
          }
        }
      }
    }
    .content {
      .order-info {
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding-bottom: 24px;
        .order-info-left {
          font-size: 16px;
          line-height: 22px;
          .count-down-area {
            margin-top: 8px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: baseline;
            -ms-flex-align: baseline;
            align-items: baseline;
            .count-down {
              margin: 0 8px;
              font-size: 16px;
            }
          }
        }
        .order-info-right {
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end;
          .payment-price {
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            .lable {
              margin-right: 8px;
            }
            .value {
              font-size: 24px;
              color: red;
              font-weight: 700;
            }
          }
        }
      }
      .view-order-btn {
        padding: 8px 0;
        font-size: 14px;
        color: #003e89;
        border-top: 1px solid #eaeaea;
        span {
          cursor: pointer;
        }
      }
      .select-payment-method {
        box-shadow: 0 0 10px 0 #74778a4d;
        background: #fff;
        padding: 24px 16px 32px;
        margin-top: 32px;
        margin-bottom: 40px;
        .payment-type {
          line-height: 20px;
          font-size: 14px;
        }
        .select-payment {
          display: flex;
          margin: 16px 0 32px;
          .el-radio {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 125px;
            height: 40px;
            background: #fff;
            border: 2px solid #b1b1b1;
          }
        }
      }
      .footer-bar {
        position: static;
        .confirm-btn {
          text-align: right;
          padding: 12px 0;
          .el-button {
            background: #003e89;
            border-radius: 2px;
            font-size: 18px;
            min-width: 146px;
            height: 54px;
          }
        }
      }
    }
  }

}
@media screen and (max-width: 1050px) {
  .checkout {
    .checkout-box {
      margin: 0 auto;
      .header {
        .header-title {
          display: none;
        }
        /deep/.order-steps {
          position: relative;
          width: 100%;
        }
      }
      .content {
        .order-info {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          .order-info-left {
            line-height: 24px;
            .count-down-area {
              display: -webkit-box;
              .count-down {
                display: inline;
              }
            }
          }
          .order-info-right {
            margin-top: 30px;
            .payment-price {
              -webkit-box-pack: end;
              -ms-flex-pack: end;
              justify-content: flex-end;
              // color: #2d17f0;
            }
          }
        }
      }
    }
    /deep/.el-dialog{
      width: 80%;
        .el-dialog__header {
    background-color: #eef0f1;
    border: 1px solid #dcdfe6;
    .el-dialog__title {
      font-weight: 700;
      font-size: 14px;
    }
  }
   .el-dialog__body {
    padding-bottom: 20px;
    text-align: center;
    padding-top: 20px;
    .payment-price {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 15px;
      span {
        color: #003e89;
      }
    }
    .qr-code {
      margin: 0 auto;
      border: 1px solid #dcdfe6;
      // width: 380px;
      .middle-icon {
        width: 198px !important;
        height: 198px !important;
        margin: 0 auto;
        background: no-repeat top;
        background-size: contain;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        img {
          position: absolute;
          width: 44px;
        }
      }
      span {
        display: inline-block;
        margin-top: 12px;
        font-weight: 700;
        font-size: 14px;
      }
    }
    .pay-warning {
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: 14px;
      margin-top: 12px;
      .count-down-area {
        margin-top: 8px;
        // display: flex;
        // -webkit-box-align: baseline;
        // -ms-flex-align: baseline;
        // align-items: baseline;
      }
    }
  }
    }
  }
}
</style>